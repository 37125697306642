import {asyncAction, createTypes} from 'redux-action-types';
import {ajax} from '../../api/ajax';
import {URL_COUNTRIES, URL_LANGUAGES, URL_LOCALES} from '../../api/routes';

export const types = createTypes(
    'Locale.',
    'SET_LANGUAGE',
    'SET_COUNTRY',
    asyncAction('GET_LANGUAGES'),
    asyncAction('GET_COUNTRIES'),
    asyncAction('GET_TRANSLATIONS'),
    // 'SET_ALTERNATIVE_LOCATES'
);

export const setLanguage = payload => ({type: types.SET_LANGUAGE, payload});
export const setCountry = payload => ({type: types.SET_COUNTRY, payload});

export const getCountries = () => (dispatch, getState) => {
  const {
    locate: {language}
  } = getState();
  dispatch({type: types.GET_COUNTRIES_REQUEST});

  return ajax({
    url: URL_COUNTRIES,
    cb: res => dispatch({type: types.GET_COUNTRIES_SUCCESS, payload: res.data}),
    err: err => dispatch({type: types.GET_COUNTRIES_FAIL, payload: err}),
    language: language === 'uk' ? 'UA' : language.toUpperCase()
  });
};

export const getLanguages = () => (dispatch, getState) => {
  const {
    locate: {language}
  } = getState();
  dispatch({type: types.GET_LANGUAGES_REQUEST});

  return ajax({
    url: URL_LANGUAGES,
    cb: res => {
      res.data.forEach(i => {
        i.id = i.code === 'uk' ? 'ua' : i.code;
      });
      dispatch({
        type: types.GET_LANGUAGES_SUCCESS,
        payload: res.data
      });
    },
    err: err => {
      dispatch({type: types.GET_LANGUAGES_FAIL, payload: err});
    },
    language: language === 'uk' ? 'UA' : language.toUpperCase()
  });
};

export const getTranslations = () => (dispatch, getState) => {
  const {
    locate: {language}
  } = getState();
  dispatch({type: types.GET_TRANSLATIONS_REQUEST});

  return ajax({
    url: URL_LOCALES,
    cb: res => dispatch({type: types.GET_TRANSLATIONS_SUCCESS, payload: res.data}),
    err: err => dispatch({type: types.GET_TRANSLATIONS_FAIL, payload: err}),
    language: language === 'uk' ? 'UA' : language.toUpperCase()
  });
};
