export const api = (axios, message, URL_API) => {
    return ({
                baseURL = URL_API,
                url,
                method = 'GET',
                headers,
                data = {},
                cbRes = () => null,
                cb = () => null,
                err = res => handleError(res, message),
                isFormData = false,
                language
            }) => {
        headers = {
            'Content-Type': !isFormData ? 'application/json' : 'multipart/form-data; boundary=blob',
            Accept: 'application/json',
            'Accept-Language': language || 'UA',
            Authorization: 'BasicCustom',
            'X-Requested-With': 'XMLHttpRequest',
            ...headers
        };

        try {
            if (window) {
                const {language: userLanguage} = window.localStorage;
                if (!language && userLanguage) headers['Accept-Language'] = userLanguage;
            }
        } catch(e) {
        }

        if (!isFormData) {
            data = JSON.stringify(data);
        }

        return axios({
            url,
            baseURL,
            method,
            headers,
            data,
            crossDomain: true,
            withCredentials: true
        })
        .then(response => {

            cbRes(response);
            return cb(response.data);
        })
        .catch(res => {
            console.log(`Error: ${res}`);

            if (res.response && res.response.status === 401) {
                console.log(`401 Unauthorized: ${res}`);
            }
            if (res.response && +res.response.status >= 500) {
                message.error('Server error');
            }

            try {
                const response = res && res.request && res.request.response;
                const parseRes = !response ? {} : JSON.parse(response);
                const {errors = []} = parseRes;

                err(errors, res);
            } catch(err) {
                console.log('ajax err', err);
            }
        });
    }
}

const handleError = (res, message) => {
    const data = res.response ? res.response.data : {};
    const errorText = data && data.detail ? data.detail : 'Error';

    message.error(errorText);
    console.error(res);
};
