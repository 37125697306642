export const URL_SEARCH = '/v2/search';
export const URL_SEARCH_PARSE_LINK = '/v2/search/parse_link';
export const URL_SEARCH_AVAILABLE_TABS = countryCode => `/v2/search/available_tabs?country_code=${countryCode}`;
export const URL_SEARCH_BRANDS_TERMS = '/v2/search/brands_terms';
export const URL_SEARCH_TERMS = '/v2/search/terms';
export const URL_SEARCH_FILTERS = '/v2/search/filters';
export const URL_GET_LOCATION_SLUG = '/v1/location/get_slug';
export const URL_GET_CITY_BY_PLACE_ID = '/v1/locations/search';
export const URL_COUNTRY_SETTINGS = id => '/v2/country_settings/' + id;
export const URL_COUNTRY_SETTINGS_HOME = countryCode => `/v2/country_settings/${countryCode}/home`;
export const URL_LOCALES = '/v2/locales';
export const URL_AUTH_REGISTER = '/v2/auth/register';
export const URL_AUTH_LOGIN = '/v2/auth/login';
export const URL_AUTH_LOGOUT = '/v2/auth/logout';
export const URL_AUTH_PASSWORD_FORGOT = '/v2/auth/password/forgot';
export const URL_AUTH_PASSWORD_EDIT = '/v2/auth/password/edit';
export const URL_AUTH_FACEBOOK = '/v2/auth/facebook';
export const URL_AUTH_GOOGLE = '/v2/auth/google';
export const URL_GET_USER = '/v2/profile';
export const URL_COUNTRIES = '/v2/country_settings';
export const URL_LANGUAGES = '/v2/locales/available';
export const BOOKMARK_STATION = id => '/v2/profile/favorite_station/' + id;
export const ADD_STATION = '/v2/stations';
export const URL_GET_SEO_HOME = countryCode => `/v2/seo_data/home?country_code=${countryCode}`;
export const URL_GET_SEO_SEARCH = countryCode => `/v2/seo_data/search?country_code=${countryCode}`;
export const URL_GET_SEO_BUSINESS_PAGE = (id, countryCode) => `/v2/seo_data/business_page?station_id=${id}&country_code=${countryCode}`;
export const URL_GET_CURRENT_LOCALES = '/v2/locales/current';
export const URL_GET_STYLES = '/v2/search/styles';
export const URL_SEND_ORDER = '/v2/orders';
export const URL_ORDER_CANCEL = id => `/v2/orders/${id}/cancel`;
export const URL_GET_PAYMENT_STATUS = id => `/v2/orders/${id}/check_payment_status`;
export const FETCH_STATION_USER_ORDERS = id => `/v2/stations/${id}/orders`;
export const FETCH_STATION_USER = id => `/v2/stations/${id}`;
export const FETCH_STATION_PROJECTS = id => `/v2/stations/${id}/projects`;
export const SEARCH_BRANDS_BY_STATION = '/v2/brands/search';
export const SEARCH_MASTERS_BY_STATION = '/v2/slots/search';
export const SEARCH_SERVICES_BY_STATION = '/v2/services/search';
export const FETCH_PROJECT = (slug) => `/v2/projects/${slug}`;
export const FETCH_PROJECTS = '/v2/projects_search';
export const FETCH_PROJECTS_FILTERS = '/v2/projects_search/filters';
export const FETCH_WORKING_HOURS = id => `/v1/stations/${id}/working_hours`;
export const FETCH_STATION_ORDER_SETTINGS = id => `/v2/stations/${id}/settings`;
export const URL_GET_LOCATION_BY_IP = '/v2/location/locate_me';
export const FILTER_MASTERS_SERVICES = '/v2/orders/filter_slots_services';
